import React, {useState, useEffect} from 'react';
import {Card, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
import {Leaderboard as LeaderboardIcon, ExpandMore as ExpandMoreIcon} from "@mui/icons-material";
import BigTicker from "../dashboard/event/bigTicker";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import numeral from "numeral";

import {
    bigTickerSelector,
    eventSelector,
    groupLeaderboardSelector, leaderboardSelector,
    orgLeaderboardSelector
} from "../../redux/events/selectors";
import Label from "../_common/text/label";
import OrganizationLeaderboard from "./organization";
import HCDialog from "../_common/dialog";
import EventHeader from "../_common/events/header";
import TrackableButton from "../_common/buttons/trackable";
import {
    fetchEvent,
    fetchEventBigTickers,
    fetchEventLeaderboard,
    fetchEventOrganizationLeaderboard
} from "../../redux/events";
// import api from "../../api";

import tamu from '../../img/sponsors/TAMU-logo-square-1.png';
import LoadingView from "../_common/loading";

interface Props {
    eventId: number;
    registration?: any;
}

const Leaderboard: React.FC<Props> = ({ eventId, registration }) => {
    const [showOrgLeaderboard, setShowOrgLeaderboard] = useState<string|number|undefined|null>(null);
    const event = useSelector(eventSelector(eventId));
    const bigTicker = useSelector(bigTickerSelector(eventId));
    const leaderboard = useSelector(leaderboardSelector(eventId));
    const orgLeaderboard = useSelector(orgLeaderboardSelector(event?.id, registration?.orgId));
    const groupLeaderboard = useSelector(groupLeaderboardSelector(event?.id, registration?.groupId));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!leaderboard && eventId) {
            // @ts-ignore
            dispatch(fetchEventLeaderboard(eventId, enqueueSnackbar));
        }
    }, [leaderboard, dispatch, eventId, enqueueSnackbar]);

    useEffect(() => {
        if (!event && eventId && eventId !== -1) {
            // @ts-ignore
            dispatch(fetchEvent(eventId, enqueueSnackbar));
            // @ts-ignore
            dispatch(fetchEventBigTickers([eventId], enqueueSnackbar));
        }
    }, [event, dispatch, eventId, enqueueSnackbar]);

    useEffect(() => {
        if (eventId && event?.registration?.orgId && !orgLeaderboard) {
            // @ts-ignore
            dispatch(fetchEventOrganizationLeaderboard(eventId, event?.registration?.orgId, enqueueSnackbar));
        }
    }, [orgLeaderboard, eventId, event?.registration?.orgId, dispatch, enqueueSnackbar]);

    return (
        <>
            <Card>
                <EventHeader bgImgUrl={event?.imageUrls.leaderboard || ""} imgUrl={event?.imageUrls.event || ""} imgAlt={event?.name} />
                <div>
                    {!leaderboard ?
                        <LoadingView /> :
                        <>
                            <Accordion disableGutters defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="text-center text-xl font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                        International Leaderboards
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="grid grid-cols-1 md:grid-cols-12">
                                        {/*<div className="md:col-span-full text-center text-xl font-bold bg-graylight mb-4 font-hc tracking-wider py-1">*/}
                                        {/*    International Leaderboards*/}
                                        {/*</div>*/}
                                        <div className="md:col-span-6 lg:col-span-3 md:px-4 md:pb-4 border-0 border-solid border-graylight md:border-r">
                                            <Label>Overall Stats</Label>
                                            <BigTicker bigTicker={bigTicker}/>
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-6 lg:col-span-3 border-0 border-solid border-graylight lg:border-r">
                                            <LeaderboardSection title="Top States" records={leaderboard.states?.filter((record: any) => record.state)} formatter={record => `${record.state}, ${record.country}`} />
                                        </div>
                                        <div className="col-span-full top-divider lg:hidden" />
                                        <div className="md:col-span-6 lg:col-span-3 border-0 border-solid border-graylight md:border-r">
                                            <LeaderboardSection title="Top Cities" records={leaderboard.cities} formatter={record => `${record.city}, ${record.state}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-6 lg:col-span-3">
                                            <LeaderboardSection title="Top Organizations" records={leaderboard.orgs} formatter={record => record.organization} />
                                        </div>
                                        <div className="col-span-full top-divider" />
                                        <div className="md:col-span-6 lg:col-span-3 border-0 border-solid border-graylight md:border-r">
                                            <LeaderboardSection title="Top People" records={leaderboard.users} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-6 lg:col-span-3 border-0 border-solid border-graylight lg:border-r">
                                            <LeaderboardSection title="Top Walk / Run" records={leaderboard.walkRun} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider lg:hidden" />
                                        <div className="md:col-span-6 lg:col-span-3 border-0 border-solid border-graylight md:border-r">
                                            <LeaderboardSection title="Top Bike / Cycle" records={leaderboard.bikeCycle} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-6 lg:col-span-3">
                                            <LeaderboardSection title="Top Swimming" records={leaderboard.swimming} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="text-center text-xl font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                        Austin's Healthy All-Stars
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="grid grid-cols-1 md:grid-cols-12">
                                        <div className="md:col-span-4 border-0 border-solid border-graylight md:border-r">
                                            <LeaderboardSection title="Kids / Teens (Under 18)" records={leaderboard.under18Austin} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-4 border-0 border-solid border-graylight md:border-r">
                                            <LeaderboardSection title="Adults (Over 18)" records={leaderboard.over18Austin} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Top Organizations" records={leaderboard.orgsAustin} formatter={record => record.organization} />
                                        </div>
                                        <div className="col-span-full top-divider">
                                            <div className="text-center font-hc">
                                                Sponsored by...
                                            </div>
                                            <div className="flex justify-center">
                                                <a href="https://ccha.tamu.edu/" target="_blank" rel="noopener noreferrer">
                                                    <img src={tamu} alt="Texas A&M University" className="w-[140px]" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="text-center text-xl font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                        Houston's Healthy All-Stars
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="grid grid-cols-1 md:grid-cols-12">
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Kids / Teens (Under 18)" records={leaderboard.under18Houston} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Adults (Over 18)" records={leaderboard.over18Houston} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Top Organizations" records={leaderboard.orgsHouston} formatter={record => record.organization} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="text-center text-xl font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                        San Antonio's Healthy All-Stars
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="grid grid-cols-1 md:grid-cols-12">
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Kids / Teens (Under 18)" records={leaderboard.under18SA} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Adults (Over 18)" records={leaderboard.over18SA} formatter={record => `${record.first_name} ${record.last_name}`} />
                                        </div>
                                        <div className="col-span-full top-divider md:hidden" />
                                        <div className="md:col-span-4">
                                            <LeaderboardSection title="Top Organizations" records={leaderboard.orgsSA} formatter={record => record.organization} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            {leaderboard.platinumUsers &&
                                <Accordion disableGutters>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className="text-center text-xl font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                            Platinum Sponsors
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {leaderboard.platinumUsers.map((user: any) => user?.Name).join(", ")}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {orgLeaderboard &&
                                <Accordion disableGutters>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className="text-center text-xl font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                            {registration?.orgName || event?.registration?.orgName}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="grid grid-cols-1 md:grid-cols-12">
                                            <div className="border-0 md:border-r border-solid border-graylight col-span-full md:col-span-6 lg:col-span-4">
                                                <LeaderboardSection
                                                    title="Top People"
                                                    records={orgLeaderboard.users}
                                                    formatter={record => `${record.first_name} ${record.last_name}`}/>
                                            </div>
                                            <div className="col-span-full top-divider md:hidden" />
                                            <div className="col-span-full md:col-span-6 lg:col-span-4">
                                                <LeaderboardSection
                                                    title="Top Groups"
                                                    records={orgLeaderboard.groups}
                                                    formatter={record => record.groupName}/>
                                            </div>
                                            <div className="col-span-full top-divider md:hidden" />
                                            <div className="col-span-full md:col-span-6 lg:col-span-4 border-0 md:border-l border-solid border-graylight">
                                                {groupLeaderboard ?
                                                    <LeaderboardSection title={registration?.groupName || ""}
                                                                        records={groupLeaderboard}
                                                                        formatter={record => `${record.first_name} ${record.last_name}`}/> :
                                                    <div>
                                                        No group registration.
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-span-full">
                                                <div className="flex justify-center mt-2">
                                                    <TrackableButton eventName="leaderboards_view_org_leaderboard_click" onClick={() => setShowOrgLeaderboard(registration?.orgId || event?.registration?.orgId)}>
                                                        <LeaderboardIcon className="mr-1" /> View Org Leaderboard
                                                    </TrackableButton>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </>
                    }
                </div>
            </Card>
            <HCDialog
                open={Boolean(showOrgLeaderboard)}
                title={<></>}
                actions={[
                    { label: 'Done', eventName: "leaderboards_org_leaderboard_done_click", onClick: () => setShowOrgLeaderboard(null) },
                ]}
                onClose={() => setShowOrgLeaderboard(null)}>
                <OrganizationLeaderboard initialEventId={eventId || ""} orgId={showOrgLeaderboard || ""} />
            </HCDialog>
        </>
    )
};

interface SectionProps {
    title: string;
    records: any[],
    formatter: (record: any) => string;
}

const LeaderboardSection: React.FC<SectionProps> = ({ title, records, formatter }) => {
    const [showMore, setShowMore] = useState(false);
    const MAX_RECORDS = 10;

    return (
        <div className="md:px-4">
            <Label>
                {title}
            </Label>
            {records && records.length ?
                <div>
                    {records.slice(0,MAX_RECORDS).map((record, index) => (
                        <div key={`${title}-${index}`} className="flex">
                            <div className="pr-1">
                                {index + 1}.
                            </div>
                            <div className="flex-1">
                                {formatter(record)}
                            </div>
                            <div className="text-right font-bold pl-4">
                                {numeral(record.totalMiles?.toFixed(0)).format("0, 0") || 0}
                            </div>
                        </div>
                    ))}
                    {records.length > MAX_RECORDS &&
                        <div className="mt-2 flex justify-center">
                            <TrackableButton eventName={`leaderboards_show_more_${title.toLowerCase().replaceAll(" ", "_")}_click`} onClick={() => setShowMore(true)}>
                                Show more...
                            </TrackableButton>
                        </div>
                    }
                </div> :
                <div>
                    Not enough data.
                </div>
            }
            <HCDialog
                open={showMore}
                title={title}
                actions={[ { label: 'Done', eventName: "leaderboards_show_more_dialog_done_click", onClick: () => setShowMore(false) }]}
                onClose={() => setShowMore(false)}>
                {records?.map((record, index) => (
                    <div key={`${title}-${index}`} className="flex min-w-[270px]">
                        <div className="pr-1">
                            {index + 1}.
                        </div>
                        <div className="flex-1">
                            {formatter(record)}
                        </div>
                        <div className="text-right font-bold pl-4">
                            {numeral(record.totalMiles?.toFixed(0)).format("0, 0") || 0}
                        </div>
                    </div>
                ))}
            </HCDialog>
        </div>
    )
}

export default Leaderboard;
